.template-homepage
    .hero
        height calc(436px + 370px)
        width 100%
        min-height calc(100vw * 0.6)
        max-height calc(100vw * 2)
        background $lighter_yellow
        position fixed
        top 0
        left 0
        +from-width($tablet_breakpoint)
            height 100vh
        +from-width($desktop_breakpoint)
            height 100vh
            max-height calc(100vw * 0.6)
        .bubbly
            position absolute
            top 150px
            left 50%
            transform translateX(-50%)
            max-width 250px
            margin 0 auto
            width 100%
            z-index 2
            +from-width($tablet_breakpoint)
                max-width 300px
            +from-width($desktop_breakpoint)
                max-width 52vw
        .bg-wrapper
            position absolute
            width 100%
            bottom -30px
            left 0
            // height 100%
            +from-width($desktop_breakpoint)
                bottom -120px
                // height auto
            img
                height 100%
                object-fit cover
                object-position top
                +from-width($desktop_breakpoint)
                    height auto
    .top-surface
        position relative
        z-index 1
    .honeyb-can
        // height 100vh
        height calc(436px + 370px - 25px)
        width 100%
        position relative
        z-index 1
        min-height calc(100vw * 0.6)
        max-height calc(100vw * 2)
        // +from-width($tablet_breakpoint)
        //     height 70vh
        +from-width($desktop_breakpoint)
            // max-height calc(100vw * 0.65)
            // min-height calc(100vw * 0.65)
            height calc(100vw * 0.62)
            // height 108vh
        .img-wrapper
            position absolute
            // bottom -25px
            top 370px
            left 50%
            transform translateX(-50%)
            max-width 370px
            margin 0 auto
            width 100%
            +from-width($tablet_breakpoint)
                max-width 400px
            +from-width($desktop_breakpoint)
                top auto
                bottom -4vw
                max-width 36vw
    .story
        position relative
        border-radius $sRounded $sRounded 0 0
        overflow hidden
        .bg-wrapper
            background $text_black
            position absolute
            overflow hidden
            top 0
            left 0
            width 100%
            height 100%
            img
                width 100%
                object-fit cover   
            .deco
                position absolute
                width 100%
                left 0
            .deco-1
                top 0
            .deco-3
                bottom 0
        .on-top
            position relative
            z-index 1
            padding 130px 0
            +from-width($desktop_breakpoint)
                padding 10vw 0 15vw
        .content
            margin-top 6vw
        h2
            color $light_yellow
            font-weight 900
            +from-width($desktop_breakpoint)
                font-weight 800
        .story-flex
            gap 26px
            align-items center
            flex-direction column
            +from-width($tablet_breakpoint)
                gap 5%
                flex-direction row
            +from-width($desktop_breakpoint)
                gap 10%
        p, h4
            text-align center
            color $lighter_yellow
            +from-width($tablet_breakpoint)
                text-align left
        .story-bottom-mb
            margin-top 75px
            .img-story
                position relative
                .deco-2
                    position absolute
                    top 0
                    left 0
            .story-bottom
                margin-top 26px
                p
                    margin-top 1em
            .img-bottom
                margin-top 113px
        .left
            margin-top 80px
            +from-width($tablet_breakpoint)
                width 30%
            .bottom
                margin-top 8vw
                p
                    margin-top 1em
        .right
            +from-width($tablet_breakpoint)
                width 65%
            +from-width($desktop_breakpoint)
                width 60%
            .top
                +from-width($desktop_breakpoint)
                    width 'calc(100% - %s)' % $side
        .paragraph-flex
            flex-direction column
            gap 1em
            +from-width($tablet_breakpoint)
                margin-bottom 7vw
                flex-direction row
                gap 2vw
            p
                text-align center
                +from-width($tablet_breakpoint)
                    text-align left
    .about
        background $lighter_yellow
        padding-top 90px
        border-radius $sRoundedMb $sRoundedMb 0 0
        overflow hidden
        margin-top -9vw
        z-index 2
        position relative
        +from-width($tablet_breakpoint)
            padding-top 10vw
            border-radius $sRounded $sRounded 0 0
        .superpower
            .block-wrapper
                position relative
                background $yellow
                border-radius 17px
                padding 70px 16px 65px
                overflow hidden
                +from-width($tablet_breakpoint)
                    border-radius 45px
                    padding 6vw
                h2, h4, p
                    color $text_black
                p
                    margin 1em auto 0
                    padding 0 8px
                    +from-width($tablet_breakpoint)
                        padding 0
                        max-width 43vw
                h4
                    font-size 18px
                    margin 2em auto 0
                    padding 0 20px
                    +from-width($tablet_breakpoint)
                        padding 0
                        margin 1em auto 0
                        max-width 43vw
                        font-size 1.7vw
            .deco
                position absolute
                mix-blend-mode multiply
            .deco-1
                top 0
                right 3vw
                width 7vw
            .deco-2
                bottom 0
                left 0
                width 12vw
        .mission
            padding 70px 0 47px
            +from-width($tablet_breakpoint)
                padding 10vw 0
            h3, p
                color $text_black
            h6
                font-weight 700
                margin-bottom 1em
            p
                margin 1em auto 0
                font-size 20px
                +from-width($tablet_breakpoint)
                    max-width 47vw
                    font-size 1.17vw
        .video
            .ratio-wrapper
                width 100%
                padding-top 75%
                position relative
                +from-width($tablet_breakpoint)
                    padding-top 43%
                .video-wrapper
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    .thumbnail-wrapper
                        position absolute
                        top 0
                        left 0
                        width 100%
                        height 100%
                        .thumbnail
                            height 100%
                            object-fit cover
                    .youtube-wrapper
                        width 100%
                        height 100%
                        .iframe-wrapper
                            width 100%
                            height 100%
                            iframe
                                width 100%
                                height 100%
        .benefit
            margin-top 96px
            padding 87px 0
            background $light_yellow
            border-radius $sRoundedMb $sRoundedMb 0 0
            +from-width($tablet_breakpoint)
                border-radius 0
                margin-top 7vw
                padding 7vw 0
            h6
                margin-bottom 1em
                font-weight 700
            .font-h6
                font-weight 700
                line-height 1.2em
            .benefit-flex
                margin-top 45px
                justify-content center
                align-items center
                flex-wrap wrap
                row-gap 30px
                +from-width($tablet_breakpoint)
                    margin-top 5vw
                    gap 0
                .item
                    padding 10px
                    flex-basis 50%
                    flex-shrink 0
                    +from-width($tablet_breakpoint)
                        padding 1.5vw 0
                        flex-basis 14.28%
                        // border-right 1.5px solid #000
                    p
                        font-size 13px
                        +from-width($tablet_breakpoint)
                            font-size 1vw
                    .icon
                        max-width 50px
                        margin 0 auto 24px
                        +from-width($tablet_breakpoint)
                            margin 0 auto 1.5vw
                            max-width 3vw

        .recipe
            +from-width($tablet_breakpoint)
                max-height 'calc(100vh - %s)' % $headerH
            @media (orientation: landscape)
                height 'calc(100vh - %s)' % $headerH
            @media (orientation: portrait)
                height auto
            .slider-wrapper
                --pleft 3em
                position relative
                height 100%
            .recipe-slider
                background #000
                height 100%
                // +from-width($tablet_breakpoint)
                //     background none
                .swiper-slide
                    background #000
                    height auto !important
                .slide-flex
                    height 100%
                    flex-direction column
                    +from-width($tablet_breakpoint)
                        flex-direction row
                    .recipe-img
                        height 355px
                        +from-width($tablet_breakpoint)
                            height 100%
                        img
                            @media (orientation: portrait)
                                object-position 50% 20%
                            height 100%
                            object-fit cover
                .relative-wrapper
                    position relative
                .recipe-bg
                    height 100%
                    object-fit cover
                .absolute-wrapper
                    background #000
                    +from-width($tablet_breakpoint)
                        background none
                        position absolute
                        top 0
                        left 0
                        width 100%
                        height 60%
                    .font-h6
                        font-size 11px
                        +from-width($desktop_breakpoint)
                            font-size 0.95vw
                    .inner
                        padding 41px $cpad 10px
                        +from-width($tablet_breakpoint)
                            padding 41px $cpad 22px
                        +from-width($desktop_breakpoint)
                            padding var(--pleft) 0 0 var(--pleft)
                    .heading
                        padding-bottom 1em
                        border-bottom 1px solid #fff
                    .heading-flex
                        justify-content space-between
                        .right, .right span
                            color $yellow
                    .list
                        +from-width($tablet_breakpoint)
                            margin-top 20px
                        +from-width($desktop_breakpoint)
                            margin-top 30px
                        .title
                            color #B4D200
                        .desc
                            margin-top 5px
                            +from-width($tablet_breakpoint)
                                margin-top 5px
                            +from-width($desktop_breakpoint)
                                margin-top 12px
                            font-weight 500
                            line-height 1.1em
                        .list-item
                            margin-top 10px
                            +from-width($tablet_breakpoint)
                                margin-top 15px
                            +from-width($desktop_breakpoint)
                                margin-top 35px
                            &:first-child
                                margin 0
            .recipe-name-absolute-wrapper
                width 100%
                background #000
                padding 10px $cpad 48px
                +from-width($tablet_breakpoint)
                    padding 10px $cpad
                    bottom 20px
                    position absolute
                +from-width($desktop_breakpoint)
                    padding 22px $cpad 48px
                    padding 0
                    background none
                    bottom 20px
                    right 0
            .recipe-name-slider
                +from-width($tablet_breakpoint)
                    padding-left var(--pleft)
                .recipe-name-nav
                    display flex
                    gap 1vw
                    justify-content flex-end
                    margin-bottom 20px
                    +from-width($tablet_breakpoint)
                        margin-bottom 2vw
                .nav
                    width 29px
                    height 29px
                    border 2px solid $light_yellow
                    border-radius 100%
                    padding 0.5vw
                    background-color $light_yellow
                    cursor pointer
                    transition all .3s ease
                    svg path
                        transition all .3s ease
                        fill #000
                    +from-width($desktop_breakpoint)
                        padding 5px
                        width 50px
                        height 50px
                        border 2.6px solid $light_yellow
                .swiper-button-disabled
                    cursor default
                    transition all .3s ease
                    background transparent
                    svg path
                        transition all .3s ease
                        fill $light_yellow
                .recipe-name
                    font-size 32px
                    font-weight 700
                    color $light_yellow
                    line-height 0.9em
                    text-align right
                    +from-width($desktop_breakpoint)
                        font-weight 900
                        font-size 3.5vw
                    
        .where
            background #fff
            padding 80px 0
            +from-width($desktop_breakpoint)
                padding 7vw 0 9vw
            h3
                margin-bottom 60px
                +from-width($desktop_breakpoint)
                    margin-bottom 1.5em
            .locate-wrapper
                height 80px
                +from-width($desktop_breakpoint)
                    height 7.5vw
                .swiper-wrapper
                    transition-timing-function linear !important
                .swiper-slide
                    max-width 200px
                    width auto
                .locate-slider
                    overflow visible
                    height 100%
                    .slick-list
                    .slick-track
                    .slick-slide
                    .img-wrapper
                        height 100%
                    img
                        height 100%
                        width auto
                        object-fit contain
                        
            .shop-wrapper
                width 100%
                overflow auto
                height 80px
                padding 10px 0
                scrollbar-width none
                -ms-overflow-style none
                +from-width($desktop_breakpoint)
                    padding 1vw 0
                    height 7.5vw
            .shop-wrapper::-webkit-scrollbar 
                display none

            .wrapper
                padding 0 $side
                overflow visible
                width max-content
                height 100%
            .shop-flex
                overflow visible
                // justify-content space-between
                align-items center
                gap 40px
                height 100%
                +from-width($desktop_breakpoint)
                    gap 4vw
                .item
                    white-space nowrap
                    flex-shrink 0
                    height 100%
                    img
                        height 100%
                        width auto
                        object-fit contain
        
    .sustain
        position relative
        border-radius $sRoundedMb $sRoundedMb 0 0
        overflow hidden
        margin-top -2vw
        z-index 2
        +from-width($tablet_breakpoint)
            border-radius $sRounded $sRounded 0 0
        .absolute-wrapper
            position relative
            margin-top -50%
            height 100%
            width 100%
            padding 30px 0 100px
            background linear-gradient(to bottom, 
                                        rgba(255, 255, 255, 0) 0%, 
                                        rgba(255, 255, 255, 1) 10%, 
                                        rgba(255, 255, 255, 1) 100%)
            +from-width($tablet_breakpoint)
                margin-top 0
                position absolute
                height auto
                top auto
                bottom 30px
            +from-width($desktop_breakpoint)
                background none
                bottom auto
                top 0
                left 0
                height 100%
                width 100%
                padding 5vw 0 7vw
            .max-width
                +from-width($desktop_breakpoint)
                    max-width 45vw
                    margin 0 5vw 0 auto
                &.bm
                    +from-width($desktop_breakpoint)
                        max-width 55vw
                        margin 0 0 0 auto
                h6
                    font-weight 700
                .quote
                    margin 1em 0
                    font-size 22px
                    line-height 1em
                    font-weight 900
                    font-family "Schibsted Grotesk", sans-serif
                    +from-width($desktop_breakpoint)
                        font-size 1.7vw
                .top
                    margin-bottom 2em
        .block-flex
            max-width 200px
            margin 40px auto 0
            width 100%
            gap 22px
            flex-direction column
            align-items center
            height 100%
            +from-width($tablet_breakpoint)
                justify-content center
                align-items stretch
                gap 1.5vw
                max-width none
                flex-direction row
            +from-width($desktop_breakpoint)
                max-width 77vw
                margin 5vw 0 0 auto
                justify-content flex-end
            &.bm
                +from-width($desktop_breakpoint)
                    margin-top 3vw
            .item
                border-radius 13px
                width 204px
                +from-width($tablet_breakpoint)
                    width auto
                +from-width($desktop_breakpoint)
                    border-radius 20px
                .item-flex
                    align-items center
                    justify-content center
                    height 100%
                .md
                    font-size 19px
                    letter-spacing -0.02em
                    line-height 1.1em
                    font-weight 600
                    +from-width($tablet_breakpoint)
                        font-size 1.7vw
                .lg
                    font-size 48px
                    letter-spacing 0.02em
                    line-height 1.1em
                    +from-width($tablet_breakpoint)
                        font-size 4vw
            .yellow
                background $yellow
                padding 15px 15px
                +from-width($desktop_breakpoint)
                    padding 1vw 1vw 1vw 2vw
                .icon-wrapper
                    flex-shrink 0
                    max-width 32px
                    +from-width($desktop_breakpoint)
                        max-width 2.5vw
                .item-flex
                    gap 10px
                    +from-width($desktop_breakpoint)
                        gap 1vw
            .green
                background #CBED00
                .item-flex
                    gap 7px
                    +from-width($desktop_breakpoint)
                        gap 0.6vw
            .green-1
                padding 28px 14px 20px 14px
                position relative
                +from-width($desktop_breakpoint)
                    padding 2vw 1vw 1vw 1vw
            .green-2
                flex-basis 25%
                padding 11px 24px
                display flex
                justify-content center
                align-items center
                +from-width($desktop_breakpoint)
                    padding 1vw 2.5vw
            .absolute-tag
                position absolute
                top 10px
                left 15px
                +from-width($desktop_breakpoint)
                    top 0.9vw
                    left 1vw
                .tag
                    background #000
                    border-radius 4px
                    color #fff
                    font-weight 600
                    font-size 10px
                    line-height 1.1em
                    letter-spacing -0.02em
                    padding 3px 6px
                    +from-width($desktop_breakpoint)
                        padding 0.2vw 0.5vw
                        border-radius 7px
                        font-size 0.8em

    .user-story
        background $light_yellow
        border-radius $sRoundedMb $sRoundedMb 0 0
        margin-top -30px
        z-index 2
        position relative
        padding 75px 0
        +from-width($tablet_breakpoint)
            margin-top -60px
            border-radius $sRounded $sRounded 0 0
        +from-width($desktop_breakpoint)
            margin-top -3vw
            padding 7vw 0 10vw
        h2
            font-weight 900
            text-align center
            +from-width($tablet_breakpoint)
                text-align left
        h6
            text-align center
            margin-bottom 1em
            +from-width($tablet_breakpoint)
                text-align left
        .story-slider
            margin-top 4vw
            .heading-flex
                justify-content center
                gap 1vw
                margin-bottom 53px
                +from-width($tablet_breakpoint)
                    margin-bottom 60px
                    justify-content space-between
            .story-slider-nav
                display flex
                gap 8px
                justify-content center
                margin-bottom 2vw
                margin-top 40px
                position relative
                z-index 2
                +from-width($tablet_breakpoint)
                    margin-top 0
                    justify-content flex-end
                    gap 1vw
                    margin-bottom 0
            .nav
                width 43px
                height 43px
                border 2px solid #000
                border-radius 100%
                padding 0.5vw
                background-color #000
                cursor pointer
                transition all .3s ease
                +from-width($desktop_breakpoint)
                    border 2.6px solid #000
                    width 4vw
                    height 4vw
                svg path
                    transition all .3s ease
                    fill #fff
            .swiper-button-disabled
                cursor default
                transition all .3s ease
                background transparent
                svg path
                    transition all .3s ease
                    fill #000
        .wrapper
            border-radius 16px
            padding 36px 36px
            +from-width($desktop_breakpoint)
                padding 2vw
        .profile
            margin-top 36px
            +from-width($desktop_breakpoint)
                margin-top 2vw
            .profile-flex
                gap 1vw
                align-items center
                .name
                    font-weight 600
                .location
                    font-size 0.7em
                    line-height 1.1em
                    font-weight 200
        .swiper-slide
            &:nth-child(3n+1)
                .wrapper
                    background #ffffb4
                .name, .comment p
                    color $text_black
                .location
                    color #606060
             &:nth-child(3n+2)
                .wrapper
                    background #01b091
                .name, .comment p, .location
                    color #fff
             &:nth-child(3n+3)
                .wrapper
                    background #ee5b52
                .name, .comment p, .location
                    color #fff
    
    .contact
        margin-top -35px
        z-index 2
        position relative
        +from-width($tablet_breakpoint)
            margin-top -2vw
        #contactUs
            cursor pointer
        .absolute-wrapper
            position absolute
            top 0
            left 0
            width 100%
            padding 98px 0 40px
            height 100%
            +from-width($tablet_breakpoint)
                padding 10vw 0 5vw
            .content-wrapper
                height 100%
            .content-flex
                height 100%
                position relative
                flex-direction column
                justify-content space-between
                +from-width($tablet_breakpoint)
                    flex-direction row
            .honeyb-logo
                max-width 115px
                width 100%
                +from-width($tablet_breakpoint)
                    max-width 12vw
            .bubbly
                margin-top 21px
                max-width 300px
                width 100%
                +from-width($desktop_breakpoint)
                    max-width 35vw
                    margin-top 3vw

            .left
            .right
                +from-width($tablet_breakpoint)
                    width 50%
            .right
                display flex
                flex-direction column
                justify-content flex-end
                gap 0
                +from-width($tablet_breakpoint)
                    gap 8vw
                .top
                    display flex
                    flex-direction column
                    gap 1.5vw
                    .a-flex
                        justify-content flex-end
                        gap 0.2vw
                    span
                        font-size 22px
                        font-weight 600
                        line-height 1.1em
                        +from-width($tablet_breakpoint)
                            font-size 1.4vw
                    img
                        max-width 18px
                        object-fit contain
                        +from-width($tablet_breakpoint)
                            max-width 1.2vw
            .contact-us
                margin-top 39px
                +from-width($tablet_breakpoint)
                    margin 0
                .outer-flex
                    justify-content center
                    +from-width($tablet_breakpoint)
                        justify-content flex-end
                .contact-flex
                    gap 10px
                    justify-content center
                    border-bottom 5px solid #000
                    +from-width($tablet_breakpoint)
                        justify-content flex-end
                        border-bottom 10px solid #000
                        gap 1vw
                    img
                        max-width 45px
                        object-fit contain
                        +from-width($tablet_breakpoint)
                            max-width 5vw
                    span
                        font-size 40px
                        font-weight 700
                        +from-width($tablet_breakpoint)
                            font-size 5vw
                        &.bm
                            +from-width($tablet_breakpoint)
                                font-size 4vw
            .copyright
                font-weight 700
                margin-top 19px
                font-size 10px
                color #000
                text-align center
                +from-width($tablet_breakpoint)
                    text-align left
                    color $light_yellow
                    margin 0
                    bottom 0
                    left 0
                    position absolute
                    font-size 0.8vw
