// popup
#communityPopup
.form-success
    position fixed
    top 0
    left 0
    height 100vh
    width 100vw
    z-index 99999
    background rgba(0,0,0,0.2)
    display none
    backdrop-filter blur(15px)
    .popup-flex
        height 100%
        width 100%
        justify-content center
        align-items center
        padding $cpad
        overflow-y auto
        .block-wrapper
            max-width 560px
            width 100%
            margin auto
            +from-width($lg_desktop_breakpoint)
                max-width 40vw
        .block
            background #F8BF03
            border-radius 20px
            position relative
            padding 40px $cpad 20px
            +from-width($desktop_breakpoint)
                padding 4vw 3vw
        .close-popup
            width 30px
            position absolute
            cursor pointer
            top $cpad
            right $cpad
            +from-width($desktop_breakpoint)
                top 1vw
                right 1vw
                width 2vw
        .heading
            margin-bottom 3vw
            h3
                font-weight 700
            p
                margin-top 1em
                font-weight 400

        // form
        .wpcf7-response-output
            display none
        .form-row
            margin-bottom 2vw
            position relative
            text-align center
        
        .label-wrapper
            &.text
            &.textarea
            &.email
                position absolute
                // top 50%
                // transform translateY(-50%)
                z-index 1
                transition all .3s ease
                pointer-events none
            label
                font-size 12px
                font-weight 400
                white-space nowrap
                color rgba(0,0,0,0.5)
                +from-width($desktop_breakpoint)
                    font-size 1vw
        
        // error msg
        .wpcf7-not-valid-tip
            font-size 0.8vw
            text-align left

        // text input
        .wpcf7-text
        .wpcf7-textarea
            color #000
            border none
            border-bottom 1px solid $black
            background transparent
            margin-top 25px
            padding 5px 0
            outline none
            font-size 14px
            width 100%
            line-height 1.5em
            border-radius 0px
            font-weight 300
            font-family "Schibsted Grotesk", sans-serif
            +from-width($desktop_breakpoint)
                font-size 1vw

        .wpcf7-textarea
            resize none
            
        input:-webkit-autofill {
            -webkit-box-shadow:0 0 0 50px rgba(#F8BF03, 1) inset;
            // -webkit-text-fill-color: #333;
        }

        // Submit btn
        .submit-row
            margin-top 4vw
            margin-bottom 0
        .custom-btn-style
            display flex
            gap 10px
            align-items center
            outline none
            border 1px solid $black
            border-radius 200px
            background transparent
            padding 10px 20px
            cursor pointer
            font-family "Schibsted Grotesk", sans-serif
            letter-spacing 1px
            color $black
            font-weight 600
            font-size 12px
            transition background-color .3s ease, color .3s ease
            +from-width($desktop_breakpoint)
                padding 10px 25px
                font-size 1vw
            &:disabled
                opacity 0.5
                pointer-events none
            .send-icon
                width 12px
                position relative
                +from-width($desktop_breakpoint)
                    width 1vw
            // &:hover