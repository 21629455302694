$headerH = 90px
$headerHMb = 72px
header
    height $headerHMb
    background $yellow
    z-index 9999
    position fixed
    width 100%
    +from-width($desktop_breakpoint)
        height $headerH
    .content-wrapper
        height 100%
    .header-flex
        height 100%
        justify-content space-between
        align-items center
        gap 10vw
        position relative
        +from-width($tablet_breakpoint)
            gap 5vw
    .middle
        padding-top 10px
        z-index 2
        max-width 100px
        +from-width($tablet_breakpoint)
            max-width 100px
        +from-width($desktop_breakpoint)
            left 0
            top 50%
            position absolute
            max-width 150px
            left 50%
            transform translate(-50%, -50%)
        .logo-wrapper
            cursor pointer
    #burgerBtn
        width 30px
        height 30px
        padding 5px 0
        cursor pointer
        .burger-btn-flex
            flex-direction column
            justify-content space-between
            align-items flex-end
            gap 3px
            height 100%
            width 100%
        span
            height 3.6px
            background #000
            display block
            width 100%
            &:nth-child(2)
                width 80%
    a
        color #000
        font-weight 700
        font-family "Schibsted Grotesk", sans-serif
        font-size 1vw
        line-height 1em
        letter-spacing 0.08em
    .a-flex
        gap 2vw
        align-items center
    .shop-btn
        background $light_yellow
        border-radius 114px
        padding 0.5vw 0.5vw 0.5vw 1vw
        .btn-flex
            align-items center
            gap 0.5vw
        img
            width 2vw
    #burgerMenu
        height 100vh
        width 100vw
        position fixed
        top 0
        right 0
        z-index 99999
        display none
        +from-width($tablet_breakpoint)
            display none !important
        .block
            opacity 0
            position absolute
            top 0
            right 0
            height 100%
            max-width 233px
            width 100%
            background rgba($yellow, 0.2)
            transition all .3s ease
        &.fade-in
            .block
                backdrop-filter blur(15px)
                transition opacity .3s ease
                opacity 1
        .top-menu
            height $headerHMb
            .top-flex
                height 100%
                align-items center
                justify-content flex-end
        .nav-menu
            padding 50px 0
            .menu-flex
                flex-direction column
                gap 47px
                .scrolling-section
                    font-size 14px
                    font-weight 700
                    letter-spacing 0.08em
        .close-menu
            width 30px
        .shop-btn
            font-size 14px
            font-weight 700
            letter-spacing 0.08em
            padding 10px 10px 10px 15px
            border-radius 95px
            .btn-flex
                gap 9.5px
            img
                width 20px
    .lang-btn
        border 2.5px solid #000
        border-radius 999px
        height 40px
        width 40px
        display flex
        align-items center
        justify-content center
        transition .3s all ease
        font-size 14px
        +from-width($tablet_breakpoint)
            font-size 1vw
            height 3vw
            width 3vw
        &:hover
            +from-width($desktop_breakpoint)
                background $light_yellow
                transition .3s all ease
