@import url('https://fonts.googleapis.com/css2?family=Schibsted+Grotesk:ital,wght@0,400..900;1,400..900&display=swap');
@import '../css/vendor/*.css'

// normalize-css()

/* Disable vendor prefixing for nib() */
flex-version = flex
support-for-ie = false
vendor-prefixes = official

/* Variable/Settings */
@import 'global/_variables.styl'
@import 'global/header.styl'
@import 'global/footer.styl'

/* Modules */
@import 'modules/*.styl'

*, html
    box-sizing border-box
    -moz-box-sizing border-box
    -webkit-box-sizing border-box
    margin 0
    padding 0

html
    scroll-behavior smooth

body, *
    font-family "Schibsted Grotesk", sans-serif
    margin 0
    color #000

img
    max-width 100%
    width 100%
    display block

ul
    padding 0
    margin 0
    list-style none

a
    text-decoration none
    color #000

.content-wrapper
    padding 0 $cpad
    width 100%
    margin 0 auto
    +from-width($tablet_breakpoint)
        max-width 'calc(100vw - (%s * 2))' % $side
        padding 0
.content-wrapper-side
    width 100%
    padding 0 $cpad
    +from-width($desktop_breakpoint)
        max-width 'calc(100vw - %s)' % $side
        padding 0
.content-wrapper-right
    margin 0 0 0 auto
.content-wrapper-left
    margin 0 auto 0 0
.content-wrapper-half
    width 100%
    +from-width($desktop_breakpoint)
        max-width calc(50vw)
    &.tablet-break
        +from-width($tablet_breakpoint)
            max-width calc(50vw)
.content-wrapper-side-half
    +from-width($desktop_breakpoint)
        max-width 'calc(50vw - %s)' % $side

.flex
    display flex

.flex-drop
    display flex
    flex-direction column
    +from-width($desktop_breakpoint)
        flex-direction row
.bold
    font-weight bold

.desktop
    display none
    +from-width($desktop_breakpoint)
        display block

.tablet_show
    display none
    +from-width($tablet_breakpoint)
        display block

.tablet_hide
    display block
    +from-width($tablet_breakpoint)
        display none

.mbl
    display block
    +from-width($desktop_breakpoint)
        display none
.uppercase
    text-transform uppercase

.text-center
    text-align center
    
.text-lightyellow
    color $light_yellow

.break-tablet
    br
        display none
        +from-width($tablet_breakpoint)
            display block

.break-tablet-hide
    br
        display block
        +from-width($tablet_breakpoint)
            display none
// Typo

h2
    // font-size 60px
    font-size 34px
    font-weight 800
    line-height 1em
    font-family "Schibsted Grotesk", sans-serif
    +from-width($desktop_breakpoint)
        letter-spacing 0.03em
        font-size 4vw
h3
    // font-size 43px
    font-size 36px
    font-weight 900
    font-family "Schibsted Grotesk", sans-serif
    line-height 1em
    +from-width($desktop_breakpoint)
        font-size 2.5vw

h4
    font-size 32px
    font-weight 700
    line-height 1.2em
    font-family "Schibsted Grotesk", sans-serif
    +from-width($desktop_breakpoint)
        font-size 1.7vw
        
h6
.font-h6
    // font-size 14px
    font-size 16px
    line-height 1em
    letter-spacing 0.08em
    font-weight 600
    font-family "Schibsted Grotesk", sans-serif
    +from-width($desktop_breakpoint)
        font-size 0.95vw

p
    font-size 16px
    line-height 1.5em
    letter-spacing 0.01em
    font-weight 500
    font-family "Schibsted Grotesk", sans-serif
    +from-width($desktop_breakpoint)
        font-size 1.17vw

// Typo - end

.video-wrapper
    position relative
    border-radius 45px
    overflow hidden
    .play-btn
        backdrop-filter blur(10px)
        border-radius 100%
        width 100px
        height 100px
        background rgba(#FFDF69, 0.5)
        position absolute
        left 50%
        top 50%
        transform translate(-50%, -50%)
        cursor pointer
        +from-width($tablet_breakpoint)
            width 120px
            height 120px
        img
            width 36px
            position absolute
            left 50%
            top 50%
            transform translate(calc(-50% + 5px), -50%)
            +from-width($tablet_breakpoint)
                width 40px
